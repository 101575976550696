body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #carousel .slick-slider .slick-arrow {
  color: "black" !important;
  background-color: "black" !important;
} */

/* input[type="number"]::-webkit-inner-spin-button, */
/* input[type="number"]::-webkit-outer-spin-button { */
#productCount::-webkit-inner-spin-button,
#productCount::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* Hide the scrollbar for the entire page */
html {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide the scrollbar for WebKit-based browsers (Chrome, Safari, Opera) */
html::-webkit-scrollbar {
  display: none;
}

/* .ant-menu-item .ant-menu-item-active .ant-menu-item-selected {
  background-color: red !important;
} */
